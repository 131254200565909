import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as profileRolesTestimonials from "./profile-roles-testimonials.module.scss"

const ProfileRolesTestimonials = ({ pageContext, location }) => {
  const pageTitle = "Testimonials"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <h1>Testimonials</h1>
        <div className={profileRolesTestimonials.testimonial}>
          <div className={profileRolesTestimonials.profile}>
            <div className="profile-image">
              <img src={
                  require("../../assets/images/careers/testimonials/sharion-sabillon-pompey.jpg")
                    .default
                }
                alt=""
                className={profileRolesTestimonials.img}
              />
            </div>
            <div className={profileRolesTestimonials.info}>
              <h2>Sharon Sabillon-Pompey</h2>
              <p>Retail Sales and Operations Specialist at Lehigh CustomFit</p>
            </div>
          </div>
          <div className="blockquote">
            <blockquote>It has been an amazing opportunity from the start. I have been able to expand my knowledge in so many different areas and work with people that inspire me to learn and succeed in every aspect of my life, not just the workplace.</blockquote>
          </div>
        </div>
        <hr />
        <div className={profileRolesTestimonials.testimonial}>
          <div className={profileRolesTestimonials.profile}>
            <div className="profile-image">
              <img src={
                  require("../../assets/images/careers/testimonials/erin-delong.jpg")
                    .default
                }
                alt=""
                className={profileRolesTestimonials.img}
              />
            </div>
            <div className={profileRolesTestimonials.info}>
              <h2>Erin Delong</h2>
              <p>Durango Boots Marketing Manager at Rocky Brands, Inc.</p>
            </div>
          </div>
          <div className="blockquote">
            <blockquote>The experience I've developed, the relationships I've created and the friendships I've gained at Rocky Brands have been nothing short of amazing. At Rocky, you're not just another employee, you're a member of the family.</blockquote>
          </div>
        </div>
        <hr />
        <p><a class="CTAblack" href="https://recruiting.ultipro.com/ROC1011RBD/JobBoard/7e2cec98-83e5-418d-9e0e-1a03210a5c51/?q=&o=postedDateDesc&w=&wc=&we=&wpst=" title="View Open Positions">View Open Positions</a></p>
      </div>
    </Layout>
  )
}

export default ProfileRolesTestimonials
